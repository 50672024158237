button,
a,
input,
select,
textarea,
iframe,
audio,
video,
meter,
progress {
    &:focus {
        outline: none;
    }
}
