@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes slide-in-left {
    from {
        transform: translateX(-120%);
    }

    to {
        transform: translateX(0%);
    }
}
