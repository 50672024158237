html {
    box-sizing: border-box;
}

// allow easy overriding of box-sizing
*,
*::before,
*::after {
    box-sizing: inherit;
}
