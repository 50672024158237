@function em($size) {
    $rem-size: calc($size / 16);
    @return #{$rem-size}em;
}

@function rem($size) {
    $rem-size: calc($size / 16);
    @return #{$rem-size}rem;
}

$filter-align-items: center;

// media query values
$breakpoint-mobile: 320px;
$breakpoint-tabletPortrait: 768px;
$breakpoint-tabletLandscape: 1024px;
$breakpoint-desktop: 1260px;
$breakpoint-desktopL: 1440px;
$breakpoint-desktopXL: 1920px;

// Colour variables used in the original build

// Values
$color-black: #000;
$color-white: #fff;
$color-sp-red: #d6002a;
$color-sp-blue: #6986b0;
$color-boulder: #767676;
$color-light-grey: #dedede;
$color-grey-80: #ccc;
$color-white-smoke: #eaeaea;
$color-purple: #bd13e7;
$color-dark-grey: #eaeaea94;
$color-grey: grey;
$color-off-white: #f5f5f5;

// Aliases
$color-primary: $color-black;
$color-secondary: $color-white;
$color-tertiary: $color-light-grey;
$color-highlight: $color-sp-red;
$color-success: $color-sp-blue;
$color-error: $color-sp-red;

// Flags
$color-flag-blue: $color-sp-blue;
$color-flag-purple: $color-purple;

// links
$color-link: $color-black;
$color-link-bg: $color-sp-red;

//table
$color-table-border: $color-grey-80;
$color-table-footer: $color-light-grey;
$color-table-header: $color-light-grey;
$color-table-row: $color-white-smoke;
$color-table-row-odd: $color-white;

// Horizontal rhythm

$h-xs: rem(5);
$h-s: rem(10);
$h-m: rem(15);
$h-l: rem(20);
$h-xl: rem(30);
$h-xxl: rem(50);

// Vertical rhythm

$v-xs: rem(5);
$v-s: rem(10);
$v-m: rem(15);
$v-l: rem(20);
$v-xl: rem(25);
$v-xxl: rem(40);

$filter-padding-horizontal: rem(20);
$filter-padding-vertical: rem(14);
$filter-padding-horizontal-shrink: rem(12);
$filter-padding-vertical-shrink: rem(0);

$filter-sort-tab-height: rem(58);

$sort-padding-right: rem(48);
$sort-padding-left: rem(20);
$sort-padding-vertical: rem(14);
$sort-padding-right-shrink: rem(48);
$sort-padding-left-shrink: rem(12);
$sort-padding-vertical-shrink: rem(0);

// Base Font
$base-font-family: 'Akk Pro', Arial, sans-serif, MS Gothic; //MS Gothic for japenese fonts
$base-font-size: rem(16);
$base-font-line-height: 1.4;
$base-font-size-tabletLandscape: rem(18);
$base-font-line-height-tabletLandscape: 1.56;

//line-heights
$line-height-1: 1.2;
$line-height-2: 1.3;
$line-height-3: 1.4;
$line-height-4: 1.5;
$line-height-5: 1.7;

// h0
$heading-font-size-0: rem(36);
$heading-font-size-0-mobileLarge: rem(42);
$heading-font-size-0-tabletPortrait: rem(60);
$heading-font-size-0-tabletLandscape: rem(65);
$heading-font-size-0-desktop: rem(72);

// h1
$heading-font-size-1: rem(32);
$heading-font-size-1-tabletPortrait: rem(36);
$heading-font-size-1-tabletLandscape: rem(48);

// h2
$heading-font-size-2: rem(28);
$heading-font-size-2-tabletLandscape: rem(36);

// h3
$heading-font-size-3: rem(20);
$heading-font-size-3-tabletLandscape: rem(24);

// h4
$heading-font-size-4: rem(18);
$heading-font-size-4-tabletLandscape: rem(20);

// h5
$heading-font-size-5: rem(18);

// h6
$heading-font-size-6: rem(16);

// large
$font-size-large: rem(18);
$font-size-large-tabletLandscape: rem(20);

// small
$font-size-small: rem(14);
$font-size-small-tabletLandscape: rem(16);

// xsmall
$font-size-xsmall: rem(12);
$font-size-xsmall-tabletLandscape: rem(14);

//table caption
$table-caption-font-size: rem(18);
$table-caption-font-size-tabletLandscape: rem(20);

// settings for http://lostgrid.org/

@lost gutter 0;

@lost flexbox flex;

@lost cycle auto;

@function em($size) {
    $rem-size: calc($size / 16);
    @return #{$rem-size}em;
}

@function rem($size) {
    $rem-size: calc($size / 16);
    @return #{$rem-size}rem;
}

@mixin aspect-ratio($width, $height) {
    display: block;
    height: 0;
    overflow: hidden;
    padding-bottom: calc($height / $width) * 100%;
    position: relative;

    > div,
    > video,
    > canvas,
    > svg {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    > image {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

// Mixin for Media Queries
// Accepts a number or a string

@mixin breakpoint($size) {

    @if type-of($size) == 'number' {
        @if unitless($size) {

            @media (min-width: $size * 1px) { // + '0px' makes sure it's px value
                @content;
            }
        }
        @else if unit($size) == "px" {

            @media (min-width: $size) {
                @content;
            }
        }
    }

    @else if $size == mobile {

        @media (min-width: $breakpoint-mobile) {
            @content;
        }
    }

    @else if $size == tabletPortrait {

        @media (min-width: $breakpoint-tabletPortrait) {
            @content;
        }
    }

    @else if $size == tabletLandscape {

        @media (min-width: $breakpoint-tabletLandscape) {
            @content;
        }
    }

    @else if $size == desktop {

        @media (min-width: $breakpoint-desktop) {
            @content;
        }
    }

    @else if $size == desktopL {

        @media (min-width: $breakpoint-desktopL) {
            @content;
        }
    }

    @else if $size == desktopXL {

        @media (min-width: $breakpoint-desktopXL) {
            @content;
        }
    }

    @else if $size == mobileOnly {

        @media (max-width: $breakpoint-tabletLandscape - 1) {
            @content;
        }
    }

    @else {

        @error "Sorry, but `#{$size}` is not a valid media query variable. Try mobile, tabletPortrait, tabletLandscape, desktop, desktopL, desktopXL";
    }
}

@mixin clearfix {
    &::after {
        clear: both;
    }

    &::before,
    &::after {
        content: ' ';
        display: table;
    }
}

@mixin dropshadow {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

// Font mixins

@mixin base-font() {
    font-size: $base-font-size;
    line-height: $base-font-line-height;

    @include breakpoint(tabletLandscape) {
        font-size: $base-font-size-tabletLandscape;
        line-height: $base-font-line-height-tabletLandscape;
    }
}

@mixin font-akk($type) {
    font-family: $base-font-family;

    @if $type == 'light' {
        font-weight: 300;
    }
    @else if $type == 'regular' {
        font-weight: 400;
    }
    @else if $type == 'bold' {
        font-weight: 700;
    }
}

@mixin filterandsort-font() {
    font-size: $base-font-size;
    line-height: $base-font-line-height;

    @include breakpoint(tabletLandscape) {
        font-size: $font-size-small;
        line-height: $base-font-line-height-tabletLandscape;
    }
    @include breakpoint(tabletPortrait) {
        font-size: $font-size-small;
        line-height: $base-font-line-height-tabletLandscape;
    }
}

// Horizontal Padding and Margin

@mixin filter-paddingHorizontal() {
    padding-left: $filter-padding-horizontal;
    padding-right: $filter-padding-horizontal;

    @include breakpoint(tabletLandscape) {
        padding-left: $filter-padding-horizontal-shrink;
        padding-right: $filter-padding-horizontal-shrink;
    }
    @include breakpoint(tabletPortrait) {
        padding-left: $filter-padding-horizontal-shrink;
        padding-right: $filter-padding-horizontal-shrink;
    }
}

@mixin sort-paddingHorizontal() {
    padding-left: $sort-padding-left;
    padding-right: $sort-padding-right;

    @include breakpoint(tabletLandscape) {
        padding-left: $sort-padding-left-shrink;
        padding-right: $sort-padding-right-shrink;
    }
    @include breakpoint(tabletPortrait) {
        padding-left: $sort-padding-left-shrink;
        padding-right: $sort-padding-right-shrink;
    }
}

// Vertical Rhythm mixin

@mixin vr($size: l) {
    @if $size == xs {
        margin-bottom: $v-s;
    }
    @else if $size == s {
        margin-bottom: $v-s;
    }
    @else if $size == m {
        margin-bottom: $v-m;
    }
    @else if $size == l {
        margin-bottom: $v-l;
    }
    @else if $size == xl {
        margin-bottom: $v-xl;
    }
    @else if $size == xxl {
        margin-bottom: $v-xxl;
    }
}

@mixin filter-paddingVertical() {
    padding-bottom: $filter-padding-vertical;
    padding-top: $filter-padding-vertical;

    @include breakpoint(tabletLandscape) {
        align-items: $filter-align-items;
        padding-bottom: $filter-padding-vertical-shrink;
        padding-top: $filter-padding-vertical-shrink;
    }
    @include breakpoint(tabletPortrait) {
        align-items: $filter-align-items;
        padding-bottom: $filter-padding-vertical-shrink;
        padding-top: $filter-padding-vertical-shrink;
    }
}

@mixin filtersorttab-Height() {
    height: $filter-sort-tab-height;
}

@mixin sort-paddingVertical() {
    padding-bottom: $sort-padding-vertical;
    padding-top: $sort-padding-vertical;

    @include breakpoint(tabletLandscape) {
        padding-bottom: $sort-padding-vertical-shrink;
        padding-top: $sort-padding-vertical-shrink;
    }
    @include breakpoint(tabletPortrait) {
        padding-bottom: $sort-padding-vertical-shrink;
        padding-top: $sort-padding-vertical-shrink;
    }
}

// box model reset
%elementreset {
    border: 0;
    margin: 0;
    padding: 0;
}

/* stylelint-disable */
@import './_fa-social-font-icons.css';

// Variables
$font-inherit: sans-serif;
$font-regular: 'Conv_AkkRg_Pro', $font-inherit;

$site-padding-mobile: rem(20);
$site-padding-tablet: rem(40);
$site-padding: rem(100);

$footer-font-color: $color-boulder;

$animation-ease-out: cubic-bezier(0.06, 0.72, 0.93, 1);
$animation-time-short: 250ms;

// Styles
.footer {
    background: #1a1a1a;
    color: $color-white;
    padding: rem(30) 0 rem(20);

    @include breakpoint(tabletLandscape) {
        padding: rem(90) 0;
    }

    a {
        text-decoration: none;
    }

    &__content {
        display: block;
    }

    &__link-list {
        padding-bottom: rem(24);
        border-bottom: rem(1) solid $footer-font-color;

        @include breakpoint(tabletLandscape) {
            margin: 0 auto;
            max-width: rem(1360);
            width: 100%;
        }

        &--secondary {
            border: 0;
            margin-top: 0;
            padding-top: rem(24);
            padding-bottom: 0;
        }
    }

    &__link-items {
        @include font-akk(bold);

        color: $color-white;
        display: block;
        font-size: rem(12);
        letter-spacing: -0.02em;
        padding: rem(5) 0;

        @include breakpoint(tabletLandscape) {
            display: inline-block;
            font-size: rem(14);
            padding: 0 rem(20) 0 0;
        }

        a {
            color: $color-white;
            font-size: inherit;
            line-height: inherit;
            transition: all $animation-time-short $animation-ease-out;

            &:hover,
            &:focus {
                color: $footer-font-color;
            }
        }
    }

    &__social,
    &__notice {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        width: 100%;

        @include breakpoint(tabletPortrait) {
            max-width: 50%;
        }

        &-items {
            color: $footer-font-color;
            display: inline-block;

            a {
                color: $footer-font-color;
                font-family: $font-regular;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                transition: all $animation-time-short $animation-ease-out;

                &:hover,
                &:focus {
                    color: $color-white;
                }
            }
        }
    }

    &__social {
        border-bottom: rem(1) solid $footer-font-color;
        margin: rem(20) 0;

        @include breakpoint(tabletPortrait) {
            border: none;
            justify-content: flex-end;
            margin: 0;
            text-align: right;
        }

        li.footer__social-items {
            font-size: 1.3em;
            padding-bottom: rem(10);

            @include breakpoint(tabletPortrait) {
                font-size: 1.1em;
                margin-left: 1em;
                width: rem(45);
            }
            a {
                color: $color-white;
                &:hover {
                    color:#767676;
                }
            }
        }
    }

    &__notice {
        font-family: $font-regular;
        font-size: rem(10);
        margin-top: 1.9em;

        @include breakpoint(tabletPortrait) {
            font-size: rem(12);
            justify-content: flex-start;
            margin-top: 0;
        }

        li.footer__notice-items {
            font-weight: normal;
            margin: 0 0.5em 0 0;
            color:$color-white;

            @include breakpoint(tabletPortrait) {
                margin: 0 1.4em 0 0;
            }
            a {
                color:$color-white;
                &:hover {
                    color:#767676;
                }
            }
        }
    }

    &__bottom-wrapper {
        @include breakpoint(tabletPortrait) {
            display: flex;
            flex-direction: row-reverse;
            margin: 5.2em auto 0;
            max-width: rem(1360);
            overflow: hidden;
            padding: 0;
            position: relative;
        }
    }
}

.home-data-widget {
    footer {
        @media (max-width: 767px) {
            padding-bottom: 4em;
        }
    }
}
