// Horizontal rhythm

$h-xs: rem(5);
$h-s: rem(10);
$h-m: rem(15);
$h-l: rem(20);
$h-xl: rem(30);
$h-xxl: rem(50);

// Vertical rhythm

$v-xs: rem(5);
$v-s: rem(10);
$v-m: rem(15);
$v-l: rem(20);
$v-xl: rem(25);
$v-xxl: rem(40);

$filter-padding-horizontal: rem(20);
$filter-padding-vertical: rem(14);
$filter-padding-horizontal-shrink: rem(12);
$filter-padding-vertical-shrink: rem(0);

$filter-sort-tab-height: rem(58);

$sort-padding-right: rem(48);
$sort-padding-left: rem(20);
$sort-padding-vertical: rem(14);
$sort-padding-right-shrink: rem(48);
$sort-padding-left-shrink: rem(12);
$sort-padding-vertical-shrink: rem(0);
