@function em($size) {
    $rem-size: calc($size / 16);
    @return #{$rem-size}em;
}

@function rem($size) {
    $rem-size: calc($size / 16);
    @return #{$rem-size}rem;
}

$filter-align-items: center;

// media query values
$breakpoint-mobile: 320px;
$breakpoint-tabletPortrait: 768px;
$breakpoint-tabletLandscape: 1024px;
$breakpoint-desktop: 1260px;
$breakpoint-desktopL: 1440px;
$breakpoint-desktopXL: 1920px;

// Colour variables used in the original build

// Values
$color-black: #000;
$color-white: #fff;
$color-sp-red: #d6002a;
$color-sp-blue: #6986b0;
$color-boulder: #767676;
$color-light-grey: #dedede;
$color-grey-80: #ccc;
$color-white-smoke: #eaeaea;
$color-purple: #bd13e7;
$color-dark-grey: #eaeaea94;
$color-grey: grey;
$color-off-white: #f5f5f5;

// Aliases
$color-primary: $color-black;
$color-secondary: $color-white;
$color-tertiary: $color-light-grey;
$color-highlight: $color-sp-red;
$color-success: $color-sp-blue;
$color-error: $color-sp-red;

// Flags
$color-flag-blue: $color-sp-blue;
$color-flag-purple: $color-purple;

// links
$color-link: $color-black;
$color-link-bg: $color-sp-red;

//table
$color-table-border: $color-grey-80;
$color-table-footer: $color-light-grey;
$color-table-header: $color-light-grey;
$color-table-row: $color-white-smoke;
$color-table-row-odd: $color-white;

// Horizontal rhythm

$h-xs: rem(5);
$h-s: rem(10);
$h-m: rem(15);
$h-l: rem(20);
$h-xl: rem(30);
$h-xxl: rem(50);

// Vertical rhythm

$v-xs: rem(5);
$v-s: rem(10);
$v-m: rem(15);
$v-l: rem(20);
$v-xl: rem(25);
$v-xxl: rem(40);

$filter-padding-horizontal: rem(20);
$filter-padding-vertical: rem(14);
$filter-padding-horizontal-shrink: rem(12);
$filter-padding-vertical-shrink: rem(0);

$filter-sort-tab-height: rem(58);

$sort-padding-right: rem(48);
$sort-padding-left: rem(20);
$sort-padding-vertical: rem(14);
$sort-padding-right-shrink: rem(48);
$sort-padding-left-shrink: rem(12);
$sort-padding-vertical-shrink: rem(0);

// Base Font
$base-font-family: 'Akk Pro', Arial, sans-serif, MS Gothic; //MS Gothic for japenese fonts
$base-font-size: rem(16);
$base-font-line-height: 1.4;
$base-font-size-tabletLandscape: rem(18);
$base-font-line-height-tabletLandscape: 1.56;

//line-heights
$line-height-1: 1.2;
$line-height-2: 1.3;
$line-height-3: 1.4;
$line-height-4: 1.5;
$line-height-5: 1.7;

// h0
$heading-font-size-0: rem(36);
$heading-font-size-0-mobileLarge: rem(42);
$heading-font-size-0-tabletPortrait: rem(60);
$heading-font-size-0-tabletLandscape: rem(65);
$heading-font-size-0-desktop: rem(72);

// h1
$heading-font-size-1: rem(32);
$heading-font-size-1-tabletPortrait: rem(36);
$heading-font-size-1-tabletLandscape: rem(48);

// h2
$heading-font-size-2: rem(28);
$heading-font-size-2-tabletLandscape: rem(36);

// h3
$heading-font-size-3: rem(20);
$heading-font-size-3-tabletLandscape: rem(24);

// h4
$heading-font-size-4: rem(18);
$heading-font-size-4-tabletLandscape: rem(20);

// h5
$heading-font-size-5: rem(18);

// h6
$heading-font-size-6: rem(16);

// large
$font-size-large: rem(18);
$font-size-large-tabletLandscape: rem(20);

// small
$font-size-small: rem(14);
$font-size-small-tabletLandscape: rem(16);

// xsmall
$font-size-xsmall: rem(12);
$font-size-xsmall-tabletLandscape: rem(14);

//table caption
$table-caption-font-size: rem(18);
$table-caption-font-size-tabletLandscape: rem(20);

// settings for http://lostgrid.org/

@lost gutter 0;

@lost flexbox flex;

@lost cycle auto;

@function em($size) {
    $rem-size: calc($size / 16);
    @return #{$rem-size}em;
}

@function rem($size) {
    $rem-size: calc($size / 16);
    @return #{$rem-size}rem;
}

@mixin aspect-ratio($width, $height) {
    display: block;
    height: 0;
    overflow: hidden;
    padding-bottom: calc($height / $width) * 100%;
    position: relative;

    > div,
    > video,
    > canvas,
    > svg {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    > image {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

// Mixin for Media Queries
// Accepts a number or a string

@mixin breakpoint($size) {

    @if type-of($size) == 'number' {
        @if unitless($size) {

            @media (min-width: $size * 1px) { // + '0px' makes sure it's px value
                @content;
            }
        }
        @else if unit($size) == "px" {

            @media (min-width: $size) {
                @content;
            }
        }
    }

    @else if $size == mobile {

        @media (min-width: $breakpoint-mobile) {
            @content;
        }
    }

    @else if $size == tabletPortrait {

        @media (min-width: $breakpoint-tabletPortrait) {
            @content;
        }
    }

    @else if $size == tabletLandscape {

        @media (min-width: $breakpoint-tabletLandscape) {
            @content;
        }
    }

    @else if $size == desktop {

        @media (min-width: $breakpoint-desktop) {
            @content;
        }
    }

    @else if $size == desktopL {

        @media (min-width: $breakpoint-desktopL) {
            @content;
        }
    }

    @else if $size == desktopXL {

        @media (min-width: $breakpoint-desktopXL) {
            @content;
        }
    }

    @else if $size == mobileOnly {

        @media (max-width: $breakpoint-tabletLandscape - 1) {
            @content;
        }
    }

    @else {

        @error "Sorry, but `#{$size}` is not a valid media query variable. Try mobile, tabletPortrait, tabletLandscape, desktop, desktopL, desktopXL";
    }
}

@mixin clearfix {
    &::after {
        clear: both;
    }

    &::before,
    &::after {
        content: ' ';
        display: table;
    }
}

@mixin dropshadow {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

// Font mixins

@mixin base-font() {
    font-size: $base-font-size;
    line-height: $base-font-line-height;

    @include breakpoint(tabletLandscape) {
        font-size: $base-font-size-tabletLandscape;
        line-height: $base-font-line-height-tabletLandscape;
    }
}

@mixin font-akk($type) {
    font-family: $base-font-family;

    @if $type == 'light' {
        font-weight: 300;
    }
    @else if $type == 'regular' {
        font-weight: 400;
    }
    @else if $type == 'bold' {
        font-weight: 700;
    }
}

@mixin filterandsort-font() {
    font-size: $base-font-size;
    line-height: $base-font-line-height;

    @include breakpoint(tabletLandscape) {
        font-size: $font-size-small;
        line-height: $base-font-line-height-tabletLandscape;
    }
    @include breakpoint(tabletPortrait) {
        font-size: $font-size-small;
        line-height: $base-font-line-height-tabletLandscape;
    }
}

// Horizontal Padding and Margin

@mixin filter-paddingHorizontal() {
    padding-left: $filter-padding-horizontal;
    padding-right: $filter-padding-horizontal;

    @include breakpoint(tabletLandscape) {
        padding-left: $filter-padding-horizontal-shrink;
        padding-right: $filter-padding-horizontal-shrink;
    }
    @include breakpoint(tabletPortrait) {
        padding-left: $filter-padding-horizontal-shrink;
        padding-right: $filter-padding-horizontal-shrink;
    }
}

@mixin sort-paddingHorizontal() {
    padding-left: $sort-padding-left;
    padding-right: $sort-padding-right;

    @include breakpoint(tabletLandscape) {
        padding-left: $sort-padding-left-shrink;
        padding-right: $sort-padding-right-shrink;
    }
    @include breakpoint(tabletPortrait) {
        padding-left: $sort-padding-left-shrink;
        padding-right: $sort-padding-right-shrink;
    }
}

// Vertical Rhythm mixin

@mixin vr($size: l) {
    @if $size == xs {
        margin-bottom: $v-s;
    }
    @else if $size == s {
        margin-bottom: $v-s;
    }
    @else if $size == m {
        margin-bottom: $v-m;
    }
    @else if $size == l {
        margin-bottom: $v-l;
    }
    @else if $size == xl {
        margin-bottom: $v-xl;
    }
    @else if $size == xxl {
        margin-bottom: $v-xxl;
    }
}

@mixin filter-paddingVertical() {
    padding-bottom: $filter-padding-vertical;
    padding-top: $filter-padding-vertical;

    @include breakpoint(tabletLandscape) {
        align-items: $filter-align-items;
        padding-bottom: $filter-padding-vertical-shrink;
        padding-top: $filter-padding-vertical-shrink;
    }
    @include breakpoint(tabletPortrait) {
        align-items: $filter-align-items;
        padding-bottom: $filter-padding-vertical-shrink;
        padding-top: $filter-padding-vertical-shrink;
    }
}

@mixin filtersorttab-Height() {
    height: $filter-sort-tab-height;
}

@mixin sort-paddingVertical() {
    padding-bottom: $sort-padding-vertical;
    padding-top: $sort-padding-vertical;

    @include breakpoint(tabletLandscape) {
        padding-bottom: $sort-padding-vertical-shrink;
        padding-top: $sort-padding-vertical-shrink;
    }
    @include breakpoint(tabletPortrait) {
        padding-bottom: $sort-padding-vertical-shrink;
        padding-top: $sort-padding-vertical-shrink;
    }
}

// box model reset
%elementreset {
    border: 0;
    margin: 0;
    padding: 0;
}

$animationDuration: 0.7s;
$animationOutDuration: 0.7s;

.global-header-desktop {
    border-bottom: rem(1) solid $color-light-grey;
    height: rem(121);
    margin-bottom: 0;
    padding-top: rem(24);
    position: relative;
    z-index: 10;

    > div {
        align-items: center;
        display: flex;
        justify-content: flex-end;
    }

    &__logo {
        height: auto;
        margin-right: rem(25);
        width: rem(170);

        svg {
            display: block;
            height: rem(72);
            width: 100%;
        }
    }

    &__nav {
        align-items: center;
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        position: relative;
    }

    &__list {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__link {
        @include font-akk(bold);

        color: #000;
        font-size: rem(18);
        line-height: 1;
        padding: rem(16) 0;

        &--active {
            color: $color-sp-red;
        }

        svg {
            height: 10px;
            width: 25px;
        }

        &:hover {
            color: $color-sp-red;
        }
    }

    &--small-ja {
        font-size: 15px;

        @media screen and (min-width: 1024px) {
            margin-right: rem(8.4) !important;
            padding-left: rem(8.4) !important;
            padding-right: rem(8.4) !important;
        }
    }

    &__search-form {
        animation: search-input-grow 1 forwards $animationDuration ease-in-out;
        background-color: $color-white;
        border: rem(1) solid $color-grey-80;
        height: rem(56);
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        > input {
            width: 100%;
        }

        .typeahead-dropdown {
            margin-top: rem(-15);
        }

        &--closing {
            animation: search-input-shrink 1 forwards $animationOutDuration ease-in-out;
        }
    }

    &__search-form-search-icon,
    &__search-form-close-icon {
        align-items: center;
        background-color: $color-white;
        border: none;
        color: $color-black;
        display: flex;
        height: rem(54);
        justify-content: center;
        padding: 0;
        width: rem(56);
    }

    &__search-form-search-icon {
        margin-top: rem(1);
        min-width: rem(54);

        svg {
            margin-top: rem(-4);
        }
    }

    &__search-form-close-icon {
        animation: button-grow 1 forwards $animationDuration ease-in-out;
        margin-top: 0;
        min-width: 0;
        overflow: hidden;
        width: rem(0);

        &:active,
        &:focus,
        &:hover {
            color: $color-sp-red;
        }
    }

    &__search-btn {
        align-items: center;
        background-color: $color-white;
        border: rem(1) solid $color-white;
        color: black;
        display: flex;
        justify-content: center;
        padding: 0;
        transition: color 500ms ease;
        width: rem(56);
    }

    &__sign-in {
        position: relative;
    }

    &__sign-in-btn {
        margin-left: rem(25);
        min-width: rem(120);
    }

    &__profile-menu {
        margin-left: rem(25);
        position: relative;
    }

    .dropdown-button__item {
        word-break: keep-all;
    }
}

@media screen and (min-width: 1024px) {
    .global-header-desktop {
        &__link {
            margin-right: rem(10);
            padding-left: rem(10);
            padding-right: rem(10);
        }
    }
}

@include breakpoint(desktopL) {
    .global-header-desktop {
        &__logo {
            margin-right: rem(55);
        }

        &__link {
            margin-right: rem(25);
            padding-left: rem(16);
            padding-right: rem(20);
        }

        &__sign-in-btn {
            margin-left: rem(55);
        }

        &__profile-menu {
            margin-left: rem(55);
        }
    }
}

@keyframes search-input-grow {
    0% {
        opacity: 0;
        width: rem(56);
    }

    25% {
        opacity: 1;
    }

    30% {
        opacity: 1;
        width: rem(56);
    }

    100% {
        opacity: 1;
        width: 100%;
    }
}

@keyframes button-grow {
    0% {
        width: rem(0);
    }

    60% {
        width: rem(0);
    }

    100% {
        width: rem(56);
    }
}

@keyframes search-input-shrink {
    0% {
        opacity: 1;
        width: 100%;
    }

    70% {
        opacity: 1;
        width: rem(56);
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        width: rem(56);
    }
}
